<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import Gen from '@helper/Gen';

export default {
	name: "BoFavorite",
	extends: Base,
	data(){
	return {
		Name:"BoFavorite",
		hero: {},
    font_style:{},
    font_size:{},
    sub_font_size:{},
    row:{},
    filter:{status:''}
	}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData()
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		},
		'filter.country'(){
			this.search()
		},
    'filter.product'(){
      this.search()
    },
	},
	methods:{
    submitHero(){
        BOGen.apirest('/'+this.Name, {data:this.hero,type:'updateHero'}, (err,resp)=>{
            if(resp.error) return Gen.info(resp.message, resp.style,3000,'.hero-info')           
            if(resp.success) {
                Gen.info(resp.message, resp.style,2000,'.hero-info') 
                this.refreshData()
                setTimeout(()=>{
                	$('[href="#collapseExample"]').click()
                },2100)
            }          
        }, "POST");
    },
    favorite(v){
      this.row.apd_id = v.apd_id
      this.row.type = 'updateData'
      BOGen.apirest('/'+this.Name, this.row, (err,resp)=>{
          this.row = resp.data
          $('#edit_favorite').modal()          
      },'POST');
      this.row.id = v.apd_id
      $('#edit_favorite').modal()
    },
    editFavorite(v){
      this.row.type = 'updateFavorite'
     BOGen.apirest('/'+this.Name, this.row, (err,resp)=>{
        if(resp.error==1){
          return Gen.info(resp.message, "danger",3000,'.modal-info')
        } else {
          return Gen.info(resp.message, "success", 1000,'.modal-info').then(()=>{
            $('#edit_favorite').modal('hide')    
            this.refreshData()         
         })
          
        }            
      }, "POST");
    }
	}
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
      	<div class="card">
         	<VForm @resp="submitHero" method="post">
            <div class="card-body">
                <h5 class="card-title">Hero Image</h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="wrap_slider_img">
                            <img :src="uploader(hero.ash_image_desktop)" class="img-responsive" />
                            <div class="slider_name">
                              <p>Hero Image</p>
                            </div>
                            <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                        </div>
                    </div>
                </div>
                <div class="row collapse mt-4" id="collapseExample">
                    <div class="hero-info col-12"></div>
                    <div class="col-md-4 mb-3">
                        <BoField name="ash_image_desktop" mandatory>
                            <Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="hero.ash_image_desktop" type="hero_descktop" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField name="ash_image_mobile" mandatory>
                            <Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="hero.ash_image_mobile" type="hero_mobile" uploadType="cropping"></Uploader>
                        </BoField>
                    </div>
                    <div class="col-md-8 mb-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="panel_group">
                                    <div class="panel_heading">Heading</div>
                                    <div class="panel_body">
                                        <div class="row">
                                            <div class="col-md-9">
                                              <BoField name="ash_title_en" v-model="hero.ash_title_en"></BoField>
                                            </div>
                                            <div class="col-md-9">
                                              <BoField name="ash_title_id" v-model="hero.ash_title_id"></BoField>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="control-label">Font Style</label>
                                                    <select class="form-control" v-model="hero.ash_title_font_type">
                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label class="control-label">Font Size </label>
                                                    <select class="form-control" v-model="hero.ash_title_size">
                                                        <option v-for="(v,k) in font_size" :key="k" :value="v">{{v}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <BoField :attr="{type:'color'}" name="ash_title_color" v-model="hero.ash_title_color"></BoField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="panel_group">
                                            <div class="panel_heading">Sub-Heading</div>
                                            <div class="panel_body">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <BoField name="ash_sub_title_en" v-model="hero.ash_sub_title_en"></BoField>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="ash_sub_title_id" v-model="hero.ash_sub_title_id"></BoField>
                                                    </div>
                                                    <div class="col-md-4">
		                                                <div class="form-group">
		                                                    <label class="control-label">Font Style</label>
		                                                    <select class="form-control" v-model="hero.ash_sub_title_font_type">
		                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
		                                                    </select>
		                                                </div>
		                                            </div>
		                                            <div class="col-md-2">
		                                                <div class="form-group">
		                                                    <label class="control-label">Font Size </label>
		                                                    <select class="form-control" v-model="hero.ash_sub_title_size">
		                                                        <option v-for="(v,k) in sub_font_size" :key="k" :value="v">{{v}}</option>
		                                                    </select>
		                                                </div>
		                                            </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label class="control-label">Color </label>
                                                            <input class="form-control" type="color" id="favcolor" name="favcolor" v-model="hero.ash_sub_title_color">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 mt-3">
                                <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        	</VForm>
        </div>
      </div>

      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
         <div class="card">
            <div class="card-body">
              <VForm @resp="search">
                <div class="row">
                  <div class="col-sm-5">
                            <h5 class="card-title">{{ObjectName}} List</h5>
                  </div>
                  <div class="col-sm-3">
                    <select class="form-control" v-model="filter.status" @change="search()">
                      <option value=""> -- Filter by Status --</option>
                      <option value="hl">Highlight</option>
                      <option value="fl">Flag</option>
                      <option value="fa">Favourite</option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group mb-0">
                        <div class="input-group">
                           <input type="text" v-model="filter.search"  class="form-control" placeholder="Search...">
                           <div class="input-group-append">
                              <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                  </div>
                </div>
              </VForm>
              <div class="row" v-model="data.data">
                <div class="col-lg-3 col-md-6" v-for="(v,k) in data.data" :key="k">
                   <div class="card">
                      <div class="card-body">
                         <div class="product-img">
                            <img :src="uploader(v.apd_image)">
                            <div class="pro-img-overlay">
                                <a href="javascript:void(0)" class="bg-info" @click="favorite(v)" v-tooltip="'Favorit'"><i class="fas fa-pencil-alt"></i></a>
                            </div>
                         </div>
                         <div class="product-text">
                            <h5 class="card-title m-b-0">{{v.apd_name}}</h5>
                            <small class="text-muted db">{{v.apd_year}}</small>
                            <h6 class="card-title m-b-0">
                              <i v-tooltip="'Favourite'" class="ti-heart"></i> {{v.countFavorite}} &nbsp; 
                              <i v-if="v.apd_flag==1" v-tooltip="'Flag'" class="ti-flag" style="color:#f74768; font-size: 18px;"></i>&nbsp;
                              <i v-if="v.apd_highlight=='Y'" v-tooltip="'Highlight'" class="ti-star" style="color:#dac110;font-size: 18px;"></i>
                            </h6>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
            </div>
            <div class="panel-body">
              <div class="row" v-if="NotFound">
                <div class="col-12 text-center" style="padding: 20px;">
                  <h3 class="tc">{{NotFound}}</h3>
                </div>
              </div>
              <div class="row" v-if="data.data===false">
                <div class="col-12 text-center">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <Pagination class="float-right" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                </div>
              </div>
            </div>
         </div>
      </div>

      <div id="edit_favorite" class="modal" tabindex="-1" role="dialog" aria-labelledby="edit_favorite" aria-hidden="true">
         <div class="modal-dialog modal-sm">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title" id="edit_favorite">Customers Favourite</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
               </div>
              <VForm @resp="editFavorite">
               <div class="modal-body">
                  <div class="fav">
                    <div class="col-12 modal-info"></div>
                    <div class="col-md-12">
                       <BoField name="apd_flag" :label="'Add to Customers Favourite'">
                          <div class="row">
                            <radio name="apd_flag" v-model="row.apd_flag" option="1" :attr="validation('apd_flag')">Yes</radio>
                            <radio name="apd_flag" v-model="row.apd_flag" option="2">No</radio>
                          </div>
                        </BoField>
                       <BoField name="apd_highlight" :label="'Add to Product Highlight'">
                          <div class="row">
                            <radio name="apd_highlight" v-model="row.apd_highlight" option="Y" :attr="validation('apd_highlight')">Yes</radio>
                            <radio name="apd_highlight" v-model="row.apd_highlight" option="N">No</radio>
                          </div>
                        </BoField>
                    </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="submit" class="btn btn-rounded  btn-info btn-loading">Save Changes</button>
               </div>
            </VForm>
            </div>
            <!-- /.modal-content -->
         </div>
         <!-- /.modal-dialog -->
      </div>

    </div>
  </div>
</div>
</template>
